import React from "react"
import Helmet from "react-helmet"
import TermsOfService from "@tightrope/footerlinks/terms-of-service"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"


export default function Terms() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}>
        <style type="text/css">{`
       
       .footerlinks-module--footerlinksContainer {
        position:relative;
        padding:0 0 100px 0;
      }

    `}
    </style>
          <title>Terms of Service - frompdftodoc.com</title></Helmet>
    <TermsOfService data={JSONData}>    <Helmet>Test</Helmet></TermsOfService>
    </HomepageLayout>
  )
}
